.loginForm {
    display:flex;
    flex-direction:column;
    align-items:center;
}

.loginForm section input {
    width:250px;
}

.loginForm section
{
    display:flex;
    margin:15px;
}

.loginForm img {
    max-width: calc(100vw - 240px);
}

.loginForm a {
    font-size: 18px;
}