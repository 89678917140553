.Mainview .MobileTopUp .CustomList input[type='search'], .Mainview .DigitalGiftCards .CustomList input[type='search'] {
    width:250px;
    position:relative;
    margin-bottom:-10px;
    background-color: #A6D920;
    border-radius: 10px;
    text-transform: uppercase;
}

.MobileTopUp .CustomList ul, .DigitalGiftCards .CustomList ul {
    margin:2px auto;
    width:240px;
    list-style:none;
    border:2px solid black;
    border-radius:12px;
    padding: 0px;
    height:240px;
    overflow-y:scroll;
    background-color:black;
}

.MobileTopUp .CustomList ul::-webkit-scrollbar, .DigitalGiftCards .CustomList ul::-webkit-scrollbar {
    width: 30px;
    margin-right: 10px;
}

.MobileTopUp .CustomList ul::-webkit-scrollbar-thumb, .DigitalGiftCards .CustomList ul::-webkit-scrollbar-thumb {
    background: #A6D920;
    border-radius: 10px;
}

.MobileTopUp .CustomList ul::-webkit-scrollbar-track, .DigitalGiftCards .CustomList ul::-webkit-scrollbar-track  {
    background: #323232;
    border-radius: 10px;
    margin-top: 8px;
}

.MobileTopUp .CustomList li, .DigitalGiftCards .CustomList li {
    text-align:left;
    border-bottom:1px solid white;
    margin-left:10px;
    width:90%;
}

.MobileTopUp .CustomList li span, .DigitalGiftCards .CustomList li span {
    width:95%;
    padding:10px;
    display:inline-block;
    color:white;
    cursor:pointer;
    border-radius: 12px;
}

.MobileTopUp .CustomList li span.Selected, .MobileTopUp .CustomList li span:hover, .DigitalGiftCards .CustomList li span.Selected, .DigitalGiftCards .CustomList li span:hover  {
    background-color: #A6D920; /* PHN Green */
}

.MobileTopUp .CustomList li span.Invalid, .DigitalGiftCards .CustomList li span.Invalid {
    background-color:red;
}