.MoneyOrder .ErrorPopup .NextMONumber, .MoneyOrder .ErrorPopup .NextMOCheck {
    background-color: white;
} 

.MoneyOrder .ErrorPopup input.NextMOCheck {
    width:35px;
}

.MoneyOrder .ErrorPopup input.NextMONumber {
    width:135px;
}

.App .Mainview .MoneyOrder .ErrorPopup button {
    padding: 10px 20px;
    margin: 10px 20px;
}