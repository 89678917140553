.ageVerifiedPopup {
    font-size:40px;
    min-width:700px;
}

.error {
    color: red;
}

.ageResult {
    display: flex;
    align-items: center;
}

.circle {
    display:flex;
    justify-content: center;
    align-items: center;
    min-width:75px;
    min-height:75px;
    border-radius:50%;
    padding:15px;
    color: #202020;
}

.grey {
    margin:10px;
    background-color: #202020;
}

.white {
    background-color: white;
}

.green {
    background-color: #A6D920; /* PHN Green */
}