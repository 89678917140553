.react-simple-keyboard.simple-keyboard {
  position: absolute;
  left:0px;
  bottom:0px;
  width: 100%;
  background-color:white;
  z-index:5;
}

.react-simple-keyboard.simple-keyboard.NumPad {
  left:50%;
  transform: translateX(-50%);
  max-width: 400px;
}

.react-simple-keyboard.simple-keyboard.Dynamic {
  z-index: 6;
}

.react-simple-keyboard.simple-keyboard .hg-button {
  height: 48px;
  background-color: black;
  color: white;
  border-radius: 0px;
}

.react-simple-keyboard.simple-keyboard.NumPad .hg-button {
  height: 62px;
  max-width: 130px;
  font-size: 24px;
  border-radius: 10px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button {
  width: 50px;
}

.react-simple-keyboard.simple-keyboard .hg-button:active, .react-simple-keyboard.simple-keyboard .hg-button.Active {
  background-color: #A6D920; /* PHN Green */
}

.react-simple-keyboard.simple-keyboard .hg-button.KeyboardControl {
  max-width:80px;
  margin-right: 80px;
  box-shadow: none;
  border: none;
  background-color:transparent;
  background-image: url('../../../images/keyboardClose.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.react-simple-keyboard.simple-keyboard.NumPad .hg-button.KeyboardControl {
  max-width:130px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.KeyboardControl {
  margin-left: auto;
  margin-right: 0px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Shift {
  width: 105px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Space {
  width: 410px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Arrow {
  width: 80px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Tab {
  width: 90px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Enter {
  width: 140px;
}

.react-simple-keyboard.simple-keyboard.Old .hg-button.Backspace {
  width: 110px;
}

.App .Mainview button.KeyboardControl {
  position: fixed;
  right:0px;
  bottom: 0px;
  padding: 0px;
  width: 80px;
  height: 48px;
  margin-bottom: 10px;
  background-color:transparent;
  cursor: pointer;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.App .Mainview button.KeyboardControl.Closed {
  background-image: url('../../../images/keyboardOpen.png');
}

.App .Mainview button.KeyboardControl.Open {
  background-image: url('../../../images/keyboardClose.png');
}