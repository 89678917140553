div.trainingToggle {
    position: absolute !important;
    top: 0px;
    right: 10px;
    z-index: 5 !important;
    transition: transform 0.25s;
}

.trainingToggle.on.headerTraining {
    transform: translateY(-100px);
}

.trainingToggle span {
    margin-right: 10px;
}

.toggle {
    position: relative;
    display: inline-block;
    height: 28px;
    width: 56px;
    border-radius: 14px;
    transition: background-color 0.25s ease 0s;
}

.toggle.on {
    background: #A6D920;
}

.toggle.off {
    background: #dbdbdb;
}

.toggle::before,
.toggle::after {
    display: inline-block;
    width: 28px;
    height: 16px;
    margin-top: 6px;
    transition: opacity 0.25s ease 0s;
    font-size: 12px;
}

.toggle.on::before {
    opacity: 1;
}

.toggle.off::after {
    opacity: .75;
}

.toggle.on::after,
.toggle.off::before {
    opacity: 0;
}

.toggle::before {
    content: 'ON';
}

.toggle::after {
    content: 'OFF';
}

.toggleIcon {
    position: absolute;
    height: 26px;
    width: 26px;
    cursor: pointer;
    border-radius: 50%;
    transition: transform 0.25s ease 0s;
    background-color: white;
    top: 1px;
}

.off .toggleIcon {
    transform: translateX(1px);
}

.on .toggleIcon {
    transform: translateX(29px);
}