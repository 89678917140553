.survey {
    padding: 10px;
    margin: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.survey .button {
    font-size: 24px;
    border-radius: 10px;
    padding: 15px;
    margin: 15px;
    background-color: #A6D920;
    color: black;
}

.survey .button:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}