.hoursRow {
    display:flex;
    justify-content: center;
}

.hoursOfOperation label {
    width: 100px;
    display:inline-block;
}

.hoursOfOperation .hoursRow select {
    text-transform: uppercase;
    max-width:250px;
    width:auto;
}