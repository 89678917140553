.confirmationPopup .button {
    color: black;
    background-color: #A6D920;
    padding: 10px 25px;
    border-radius: 10px;
    margin: 30px;
    font-size: 22px;
    cursor: pointer;
}

.confirmationPopup .button:hover {
    color: white;
    background-color: black;
}

.message {
    margin: 20px;
}