.reports {
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
}

.reportType, .reportControl, .reportProduct {
    display:flex;
    justify-content: center;
    align-items: center;
}

.reportProduct fieldset {
    display:flex;
    align-items: center;
}

.reportForm .reportType button, .reportForm input[type='date'], .reportForm select, .reportForm button[type='submit'] {
    font-size:20px;
    height:50px;
    padding:0px;
    width:220px;
}

.reportForm .dateInput, .reportForm .reportProduct select {
    width:180px;
}

.reportForm button[type='submit'] {
    height:40px;
    width:120px;
}

.reportForm input[type='checkbox'] {
    margin-left:8px;
}