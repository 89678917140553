.default {
    width: 100%;
    height: 66%;
    background-color: black;
    border-left: 0px;
    border-right: 0px;
    border-top: 5px;
    border-top-style: solid;
    border-bottom: 5px;
    border-bottom-style: solid;
    border-color:#A6D920;
    box-shadow: 4px 6px 11px 0px #000000;
}