.menuPopup {
    width: 100%;
    max-width: 930px;
    max-height: 80%;
    overflow: auto;
    border-width: 8px;
    border-radius: 20px;
    padding-bottom: 40px;
}

.closeable {
    z-index: 6;
}

.title {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    ;
    font-size: 20px;
}

.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

.menuItem {
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    background-color: black;
    font-size: 36px;
    box-shadow: 8px 8px 9px 1px #000000;
    position: relative;
    line-height: .9;
    color: white;
    cursor: pointer;
    user-select: none;
    transition: transform 100ms;
    flex-direction: column;
}

.menuItemSection {
    border-top: 2px solid white;
    padding-top: 10px;
    padding-bottom: 7px;
}

.menuItemSection:first-child {
    border-top: none;
    padding-top: none;
}

.menuItemSection:last-child {
    padding-bottom: none;
}

.menuItem:hover,
.menuItem:active {
    color: black;
    background-color: #A6D920;
}

.menuItem:active {
    box-shadow: 6px 6px 12px -7px #000000;
    transform: translate(6px, 6px);
}

.menuItem.disabled {
    color: #A6D920;
    background-color: #88A53D;
    pointer-events: none;
}

.new {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 75px;
    height: 75px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.new h6 {
    position: relative;
    font-size: 20px;
    color: white;
    margin: 0px;
}