.system .info label {
    display:inline-block;
    width: 150px;
}

.system .info input {
    width: 250px;
}

.system fieldset, .system {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.system .systemBody {
    display:flex;
    align-items: center;
}

.system :global(.Links) > button, .system :global(.Links) > a {
    margin: 10px 0px;
}