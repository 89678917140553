.preferences {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.preferences input[type='checkbox'] {
    zoom: 2;
}

.softKeyboardSetting, .oldKeyboardSetting, .trainingModeSetting {
    display:flex;
    justify-content: center;
    align-items: center;
}