.BillPay {
    width: 100%;
}

.BillPay fieldset {
    padding: 4px 0px;
    margin: 4px 0px;
}

.BillPay input::placeholder {
    color:black;
    opacity: 1;
}

.Mainview .BillPay input, .Mainview .BillPay select {
    display:block;
    width: 420px;
}

.BillPay .LegalMessage {
    font-style: italic;
}

.App .Mainview .BillPay input[type='search']:focus {
    border-width: 4px;
    padding: 5px;
    border-color: black;
}