.searchResults {
    text-align: left;
    border-bottom: 1px solid black;
    display:flex;
    align-items: baseline;
}

.searchResults span {
    margin-left: 10px;
}

.searchResultContent > a, .searchResultContent > a:hover {
    color: blue;
    background-color: initial;
}

.searchResultContent > p {
    border-bottom: 1px solid black;
    padding-bottom: 16px;
}

.searchResultContent > p :global(.Selected) {
    background-color: #A6D920; /* PHN Green */;
}

.searchResultContent {
    text-align: left;  
    margin-top:20px;
    height: 330px;
}

.searchResultFooter {
    margin-top: 35px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.searchResultFooter img {
    max-width: 100px;
    max-height: 50px;
}

.searchResultFooter > div {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    max-width: 440px;
    min-width: 300px;
    width: 100%;
    padding: 20px 0px;
    border: 1px solid black;
}

.nextPage {
    margin-left: auto;
}

.previousPage {
    margin-right: auto;
}
