.header {
    height: 100%;
    max-height: 60px;
    display: flex;
    position: relative;
}

.leftSection,
.rightSection {
    display: flex;
    align-items: center;
}

.leftSection {
    flex: 1 1 0px;
}

.server {
    font-size: 34px;
    color: red;
    position: absolute;
    cursor: pointer;
}

.logo {
    max-width: 235px;
    max-height: 60px;
    width: 100%;
    height: 100%;
    background-image: url('../../../../images/feHeaderLogo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.logo.MEMO {
    background-image: url('../../../../images/memoLogo.png');
    max-width: 220px;
}

.userInformation {
    white-space: nowrap;
}

.rightSection {
    justify-content: flex-end;
    flex: 0 1 0px;
}

.headerButton {
    flex: 1 1 185px;
    max-width: 185px;
    min-width: 150px;
    padding: 11px 0px;
    margin: 5px;
    font-size: 16px;
    background-color: black;
    color: white;
}

.headerButton:hover {
    background-color: #A6D920;
    color: white;
}

@media (max-width: 900px) {
    .userInformation {
        display: none;
    }
}