.FAQContainer {
    text-align: left;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 15px;
}

.FAQContainer h4 {
    text-align: center;
}

.FAQContainer .FAQEntries  a {
    display: block;
    text-decoration: underline;
    margin: 15px 0px;
}

.FAQEntries  a:hover {
    color: black;
    background-color: white;
}

.FAQEntries {
    margin-bottom: 15px;
}