.errorBlock {
    max-height: 220px;
}

.updateProgress {
    appearance: none;
    margin: 20px;
    width: 30em;
}

.updateProgress::-webkit-progress-bar {
    border-radius: 10px;
    background-color: lightgray;
}

.updateProgress::-webkit-progress-value {
    transition: width 300ms;
    border-radius: 10px;
    background-color: #A6D920;
}

.updateStatus button {
    font-size: 20px;
}