.tip a.faqlink {
    color: black;
    font-weight: bold;
}

.tips {
    flex-basis: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.main {
    margin: 20px;
    white-space: nowrap;
}

.menu,
.home {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-around;
}

@media (max-width: 1024px) {

    .menu,
    .home {
        flex-wrap: wrap;
    }

    .main {
        white-space: normal;
    }
}

.tip {
    border: 2px solid white;
    border-radius: 20px;
    background-color: #A6D920;
    color: black;
    padding: 10px;
}

.scams {
    padding-left: 20px;
    margin: 0px;
    text-align: left;
}

.scams li {
    margin-bottom: 10px;
}

.menuItem {
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    padding: 10px;
    margin: 20px;
    border-radius: 30px;
    background-color: black;
    font-size: 30px;
    box-shadow: 8px 8px 9px 1px #000000;
    position: relative;
    line-height: .9;
    color: white;
    cursor: pointer;
    user-select: none;
    transition: transform 100ms;
}

.menuItem:hover,
.menuItem:active {
    color: black;
    background-color: #A6D920;
}

.menuItem:active {
    box-shadow: 6px 6px 12px -7px #000000;
    transform: translate(6px, 6px);
}

.menuItem.disabled {
    color: #A6D920;
    background-color: #88A53D;
    pointer-events: none;
}

.amount {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.legalMessage {
    font-style: italic;
}

.cartFormButton {
    padding-left: 25px;
    padding-right: 25px;
    height: 36px;
    float: right;
}

.cartForm {
    padding-top: 0px;
}

.cartFormInput {
    text-align: center;
    width: 145px;
    font-size: 14px;
}

.cartFormInput::placeholder {
    color: white;
}

.cartFormMessage {
    display: inline-block;
    max-width: 150px;
}

.cartFormCheckoutButtons {
    display: flex;
    justify-content: space-around;
}

.cart {
    text-align: left;
    background-color: #A6D920;
    border-radius: 10px;
    border: solid 2px;
    font-size: 18px;
    padding: 6px;
    min-width: 240px;
    max-width: 390px;
    margin: auto;
}

.cartLabel {
    vertical-align: top;
    display: inline-block;
    font-weight: bold;
    width: 115px;
}

.cartDiv {
    margin: 10px 0px;
}

.cartSpan {
    min-width: 135px;
    max-width: 285px;
}

.cardForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cardFormInput {
    text-align: center;
    text-transform: uppercase;
    -webkit-text-security: disc;
}

.cardFormInput:focus,
.cardFormInput:hover {
    -webkit-text-security: none;
}

.cardFormNext {
    display: flex;
    justify-content: flex-end;
}