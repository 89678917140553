.ageForm .month, .ageForm .day {
    width:70px;
}

.ageForm .year {
    width: 100px;
}

.ageButton {
    display:flex;
    justify-content: flex-end;
}

.ageForm {
    display:flex;
    justify-content: center;
}