.MobileTopUp .PhoneForm input  {
    text-align:center;
    text-transform: uppercase;
}

.MobileTopUp .PhoneForm button {
    float:right;
}

.MobileTopUp .PhoneForm fieldset {
    margin: 0px;
    padding: 0px;
}

.Mainview .MobileTopUp .PhoneForm .CountryCode {
    width: 100px;
} 

.Mainview .MobileTopUp .PhoneForm .PromoSection button {
    border: 2px solid white;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 100px;
    padding: 10px;
    margin-right: 20px;
    border-radius: 30px;
    background-color: black;
    font-size: 30px;
    box-shadow: 8px 8px 9px 1px #000000;
    position: relative;
    line-height: .9;
    color: white;
    cursor: pointer;
    user-select: none;
    transition: transform 100ms;
    float:none;
}

.Mainview .MobileTopUp .PhoneForm .PromoSection button:hover {
    color: black;
    background-color: #A6D920;
}

.Mainview .MobileTopUp .PhoneForm .PromoSection button:active {
    box-shadow:6px 6px 12px -7px #000000;
    transform: translate(6px, 6px);
}
