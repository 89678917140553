.Mainview .MobileTopUp .ProviderProductForm fieldset, .Mainview .DigitalGiftCards .ProviderProductForm fieldset {
    vertical-align: middle;
    margin:0px;
    padding: 10px;
}

.Mainview .MobileTopUp .ProviderProductForm fieldset.CustomList, .Mainview .DigitalGiftCards .ProviderProductForm fieldset.CustomList {
    margin-top:20px;
}

.ProviderProductForm .PromoPopup {
    height: 95%;
    width: 95%;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.ProviderProductForm .PromoContents {
    max-height: 95%;
    max-width: 95%;
    overflow-y: scroll;
}

.Mainview .MobileTopUp .ProviderProductForm .PromoSection button {
    border: 2px solid white;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 270px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 30px;
    background-color: #A6D920;
    font-size: 30px;
    box-shadow: 8px 8px 9px 1px #000000;
    position: relative;
    line-height: .9;
    color: black;
    cursor: pointer;
    user-select: none;
    transition: transform 100ms;
    float:none;
}

.Mainview .MobileTopUp .ProviderProductForm .PromoSection button:hover {
    color: white;
    background-color: black;
}

.Mainview .MobileTopUp .ProviderProductForm .PromoSection button:active {
    box-shadow:6px 6px 12px -7px #000000;
    transform: translate(6px, 6px);
}