.Mainview .DigitalGiftCards .CartForm h1, .Mainview .MobileTopUp .CartForm h1 {
    margin: 0px;
    line-height: .8;
}

.Mainview .DigitalGiftCards .CartForm button, .Mainview .MobileTopUp .CartForm button {
    padding-left:25px;
    padding-right:25px;
    height:36px;
    float:right;
}

.Mainview .DigitalGiftCards .CartForm input, .Mainview .MobileTopUp .CartForm input {
    text-align: center;
    width: 145px;
    font-size:14px;
}

.Mainview .DigitalGiftCards .CartForm input::placeholder, .Mainview .MobileTopUp .CartForm input::placeholder {
    color:white;
}

.DigitalGiftCards .CartForm  .Message, .MobileTopUp .CartForm .Message {
    display:inline-block;
    max-width: 150px;
}

.DigitalGiftCards .CartForm  .CheckoutButtons, .MobileTopUp .CartForm .CheckoutButtons {
    display: flex;
    justify-content: space-around;
}