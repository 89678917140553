.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.75);
}

.overlay.fade {
    opacity: 0;
}

.overlay.fade.opening {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.overlay.fade.closing {
    opacity: 0;
    transition: opacity 200ms ease-out;
}