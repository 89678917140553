.BillPay .SearchForm .SearchStateZip {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 430px;
}

.BillPay .SearchForm .SearchStateZip input, .BillPay .SearchForm .SearchStateZip select {
    display:inline-block;
    width: 205px;
    margin: 0px 5px;
}