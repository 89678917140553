.promotions {
    max-height: 95%;
    z-index: 5;
    overflow: auto;
    text-align: left;
}

.promotionItem {
    border: 2px solid black;
    border-radius: 10px;
    padding: 5px;
    margin: 15px;
}