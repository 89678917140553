.App .Mainview .BillPay .TrainingSelectionPopup .TrainingSelectionButtons button {
    background-color: #A6D920;
    color: black;
    font-size: 25px;
    width: 200px;
    height: 200px;
    padding: 0px;
    margin: 20px;
}

.App .Mainview .BillPay .TrainingSelectionPopup .TrainingSelectionButtons button.disabled {
    pointer-events: none;
    background-color: grey;
    color: white;
}

.App .Mainview .BillPay .TrainingSelectionPopup .TrainingSelectionButtons button:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}