.supportHeader,
.supportNav {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.supportNav {
    flex-direction: row;
    flex-wrap: wrap;
}

.supportHeader h2 {
    margin: 0px !important;
}

.supportTitle a {
    font-weight: bold;
    font-size: 22px;
}

.supportHeader .supportTitle a:hover {
    color: black;
    background-color: white;
}

.supportHeader.fullSize .supportNav {
    flex-direction: column;
}

.categoryButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categoryButton {
    width: 75px;
    height: 75px;
    border: 2px solid black;
    border-radius: 50%;
    margin: 5px;
}

.supportHeader.fullSize .categoryButton {
    width: 125px;
    height: 125px;
}

.categoryButton:hover {
    background-color: #A6D920;
    /* PHN Green */
    cursor: pointer;
}

.categoryButton img {
    margin-top: 10%;
    margin-bottom: 6%;
    width: 80%;
    height: 80%;
}

.categoryButton div {
    font-weight: bold;
    font-size: 16px;
    margin-top: 16%;
    font-family: Myriad-Pro-Semibold-Condensed, sans-serif;
}

.supportHeader.fullSize .categoryButton div {
    margin-top: 26%;
    font-weight: bold;
    font-size: 22px;
}

.searchContainer {
    position: relative;
}

.supportHeader.fullSize .search {
    margin-bottom: 25px;
}

.supportHeader .search input {
    width: 360px;
    border-radius: 30px;
    font-size: 16px;
}

.supportHeader.fullSize .search input {
    width: 540px;
    font-size: 22px;
}

.search button[type='submit'] {
    position: absolute;
    padding: 5px;
    top: 1px;
    right: 1px;
    width: 38px;
    height: 38px;
    border-radius: 0px 25px 25px 0px;
}

.supportHeader.fullSize .search button[type='submit'] {
    width: 50px;
    height: 46px;
}

.moneyOrder img {
    margin-left: 7%;
}