.BillPay .CivitekPopup {
    width: 82%;
    z-index:5;
}

.Mainview .BillPay .CivitekPopup h4 {
    margin: 0px;
}

.BillPay .Civitek .FormSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.Mainview .BillPay .Civitek fieldset {
    display:flex;
    flex-direction: column;
}

.Mainview .BillPay .Civitek fieldset input, .Mainview .BillPay .Civitek fieldset select {
    margin: 10px;
    background-color: white;
}

.Mainview .BillPay .Civitek .Birthdate {
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.Mainview .BillPay .Civitek input.Month, .Mainview .BillPay .Civitek input.Day {
    width:70px;
}

.Mainview .BillPay .Civitek input.Year {
    width: 100px;
}


.BillPay .Civitek .Continue {
    width: 100%;
}

.Mainview .BillPay .Civitek .State, .Mainview .BillPay .Civitek .Zip, .Mainview .BillPay .Civitek .Name {
    display:inline-block;
}

.Mainview .BillPay .Civitek .State select,.Mainview .BillPay .Civitek .Zip input, .Mainview .BillPay .Civitek .Name input {
    margin: 5px;
}

.Mainview .BillPay .Civitek .State select {
    width:114px;
}

.Mainview .BillPay .Civitek .Zip input {
    width: 295px;
}

.Mainview .BillPay .Civitek .Name input {
    width: 205px;
}