.AMLPolicyGuide {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 19px;
    background-color: white;
    color: black;
    padding: 25px;
    text-align: left;
}

.AMLPolicyGuide * {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.AMLPolicyGuide img {
    width: 100%;
}

.AMLPolicyGuide .Center {
    text-align: center;
}

.AMLPolicyGuide h1 {
    margin: 0px !important;
    font-size: 47px !important;
    font-weight: 700;
}

.AMLPolicyGuide h2 {
    margin: 0px !important;
    font-size: 34px !important;
    font-weight: 500;
}

.AMLPolicyGuide h4 {
    margin: 0px !important;
    font-size: 26px !important;
    font-weight: 500;
}

.AMLPolicyGuide h5 {
    margin: 0px !important;
    font-size: 24px !important;
    font-weight: 500;
}

.AMLPolicyGuide h6 {
    margin: 0px !important;
    font-size: 20px !important;
    font-weight: 500;
}

.AMLPolicyGuide .Underline {
    text-decoration: underline;
}

.AMLPolicyGuide small {
    font-size: 18px;
}

.AMLPolicyGuide strong {
    font-weight: 700;
}

.AMLPolicyGuide input[type='text'],
.AMLPolicyGuide input[type='date'],
.AMLPolicyGuide textarea,
.AMLPolicyGuide .Field {
    margin: 0px 10px;
    color: black;
}

.AMLPolicyGuide button {
    margin: 20px;
}

.AMLPolicyGuide .Row {
    display: flex;
    align-items: center;
}