.CashLoad .Cart {
    text-align:left;
    background-color: #A6D920;
    border-radius: 10px;
    border:solid 2px;
    font-size: 18px;
    padding: 6px;
    min-width: 240px;
    max-width: 390px;
    margin: auto;
}

.CashLoad .Cart label {
    vertical-align:top;
    display:inline-block;
    font-weight:bold;
    width:115px;
}

.CashLoad .Cart div {
    margin:10px 0px;
}

.CashLoad .Cart span {
    min-width: 135px;
    max-width: 285px;
}