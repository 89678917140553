.updateUserInfo {
    width:100%;
}

.updateUserInfo .username {
    width:250px;
}

.updateUserInfo .type {
    width:175px;
}

.updateUserInfo fieldset {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.userFields {
    display:flex;
}

.updateUserFormControl {
    display: flex;
    justify-content: center;
}

.updateUserInfo legend {
    font-weight:bold;
}