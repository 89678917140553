.tooltipTrigger {
    position: relative;
}

.tooltip {
    display: none;
    text-align: left;
    font-family: Avenir-Book, Arial;
    font-size: 16px;
    background-color: black;
    border-radius: 3px;
    border: 1px solid black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    color: white;
    padding: 0.4rem;
    white-space: nowrap;
}

.tooltip::after {
    content: "";

    border-width: 8px;
    border-style: solid;
}

.tooltip,
.tooltip::after {
    position: absolute;

}

.tooltipTrigger.manual.visible {
    z-index: 5;
}

.tooltipTrigger.automatic:focus-within>.tooltip,
.tooltipTrigger.manual.visible>.tooltip {
    display: block;
}

.tooltip.bottom,
.tooltip.top::after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip.top,
.tooltip.bottom::after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip.left,
.tooltip.right::after {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip.right,
.tooltip.left::after {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip.topRight,
.tooltip.bottomLeft::after {
    bottom: 100%;
}

.tooltip.topLeft,
.tooltip.bottomRight::after {
    right: 0%;
    bottom: 100%;
}

.tooltip.bottomRight,
.tooltip.topLeft::after {
    top: 100%;
}

.tooltip.bottomLeft,
.tooltip.topRight::after {
    right: 0%;
    top: 100%;
}

.tooltip.bottomLeft::after {
    left: 100%;
    transform: translateX(-2em);
}

.tooltip.bottomRight::after {
    right: 100%;
    transform: translateX(2em);
}

.tooltip.topLeft::after {
    left: 100%;
    transform: translateX(-2em);
}

.tooltip.topRight::after {
    right: 100%;
    transform: translateX(2em);
}

.tooltip.highlight {
    font-weight: bold;
    background-color: red;
    border-color: red;
}

.tooltip .bold {
    font-family: Myriad-Pro-Semibold-Condensed, sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
}

.tooltip.bottom,
.tooltip.bottomRight,
.tooltip.bottomLeft {
    margin-top: 8px;
}

.tooltip.top,
.tooltip.topRight,
.tooltip.topLeft {
    margin-bottom: 8px;
}

.tooltip.left {
    margin-right: 8px;
}

.tooltip.right {
    margin-left: 8px;
}

.tooltip.bottom::after,
.tooltip.bottomLeft::after,
.tooltip.bottomRight::after {
    border-color: transparent transparent black transparent;
    margin-top: -8px;
}

.tooltip.highlight.bottom::after,
.tooltip.highlight.bottomLeft::after,
.tooltip.highlight.bottomRight::after {
    border-color: transparent transparent red transparent;
}

.tooltip.top::after,
.tooltip.topLeft::after,
.tooltip.topRight::after {
    border-color: black transparent transparent transparent;
    margin-bottom: -8px;
}

.tooltip.highlight.top::after,
.tooltip.highlight.topLeft::after,
.tooltip.highlight.topRight::after {
    border-color: red transparent transparent transparent;
}

.tooltip.left::after {
    border-color: transparent transparent transparent black;
    margin-right: -8px;
}

.tooltip.highlight.left::after {
    border-color: transparent transparent transparent red;
}

.tooltip.right::after {
    border-color: transparent black transparent transparent;
    margin-left: -8px;
}

.tooltip.highlight.right::after {
    border-color: transparent red transparent transparent;
}

.tooltip .tooltipButton {
    background-color: #A6D920;
    color: black;
    border-radius: 10px;
    padding: 5px 25px;
    margin: 5px 0px;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltipTitle {
    font-weight: bold;
    font-size: 1.5em;
    margin: 5px 0px;
}