.bannerWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: 152px;
    height: 100%;
    margin-top: 11px;
    transition: background-color 2s ease-in-out;
}

.bannerWrapper.closed {
    height: 0px;
}

.banner {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: visible;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(-100%);
}

.action {
    cursor: pointer;
}

.banner.entering {
    transition: transform 2s ease-in-out;
    transform: translateX(0%);
}

.banner.exiting {
    transition: transform 2s ease-in-out;
    transform: translateX(100%);
}

.bannerMessage {
    padding: 20px;
}