.tip {
    font-size:10px;
}

.updateSelf input, .updateSelf select {
    width:320px !important;
}

.updateSelf button[type='submit'] {
    margin:20px;
}
