.helpFields, .buttonContainer {
    display:flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.helpFields {
    flex-wrap:wrap;
}

.helpFields input {
    display:block;
}

.help {
    flex-direction: column;
}