.DigitalGiftCards .NamePhoneForm, .CashLoad .NamePhoneForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DigitalGiftCards .NamePhoneForm input, .CashLoad .NamePhoneForm input  {
    text-align:center;
    text-transform: uppercase;
}

.DigitalGiftCards .NamePhoneForm .NamePhoneNext, .CashLoad .NamePhoneForm .NamePhoneNext {
    display: flex;
    justify-content: flex-end;
}

.DigitalGiftCards .NamePhoneForm fieldset, .CashLoad .NamePhoneForm fieldset {
    padding: 0px;
}