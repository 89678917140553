.BillPay .CheckForm button.CheckConfirm {
    float:right;
    margin:10px;
}

.BillPay .CheckForm .CheckAmount input {
    text-align:center;
}

.BillPay .CheckForm fieldset {
    padding: 10px;
    margin: 10px;
}