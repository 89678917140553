.processingPopup {
    z-index: 5;
}

.processing {
    margin: 15px;
}

.processing .title {
    color: #A6D920;
}

.processing .loadingBarContainer {
    margin: 20px;
    border-radius: 20px;
    border: 2px solid #A6D920;
    animation: loadingAnimation 1s linear infinite;
    background-image: linear-gradient(to right, #A6D920 0%, black 50%, #A6D920 100%);
    background-size: 200%
}

.processing .loadingBar {
    border-radius: 20px;
    border: 4px solid black;
    height: 16px;
    background-image:
        repeating-linear-gradient(to right,
            black,
            black 4px,
            transparent 4px,
            transparent 8px);
}

@keyframes loadingAnimation {
    from {
        background-position-x: -100%;
    }

    to {
        background-position-x: 100%;
    }
}