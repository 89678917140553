.trainingOverlay {
    text-align: left;
}

.trainingModeLabel {
    display: inline-flex;
    padding-left: 100px;
    padding-right: 50px;
    border-radius: 0px 10px 10px 0px;
    background-color: black;
    color: #A6D920;
    /* PHN Green */
    transition: transform 0.25s;
}

.trainingModeLabel.sidebarTraining {
    transform: translateX(220px);
}

.trainingModeLabel span {
    color: white;
}

.trainingModeLabel h2 {
    margin: 0px;
    font-size: 52px;
}

.trainingModeLabel button {
    background-color: #A6D920 !important;
    /* PHN Green */
    color: black !important;
    width: 25px;
    height: 25px;
    margin: 5px;
    padding: 0px;
    border-radius: 5px;
    cursor: pointer;
}

.trainingModeLabelBody {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 50px;
}

.trainingModeLabel.headerTraining .trainingModeLabelBody {
    flex-direction: column;
}

.trainingModeLabelBody>div {
    margin-left: 50px;
}

.globalTrainingPopup {
    margin: 0px;
    min-width: 400px;
    min-height: 200px;
}

.globalTrainingPopupButtons button {
    background-color: #A6D920 !important;
    color: black !important;
    font-size: 25px !important;
    padding: 15px !important;
    margin: 20px !important;
    border-radius: 10px !important;
}

.globalTrainingPopupButtons button:hover {
    background-color: black !important;
    color: white !important;
    cursor: pointer;
}

.scams {
    text-align: left;
    padding-left: 25px;
}

.globalTrainingPopup a.faqlink {
    color: white;
    font-weight: bold;
}