.inputField:read-only {
    color:black !important;
    -webkit-text-fill-color: black !important;
}

.popper {
    z-index: 2;
}

.calendar {
    border: 2px solid black;
}

.calendar :global(.react-datepicker__navigation), .calendar :global(.react-datepicker__navigation:focus) {
    padding: 0px !important;
    border-radius: 0px !important;
    border: 1rem solid transparent !important;
    background-color: white !important;
}

.calendar :global(.react-datepicker__navigation--previous), .calendar :global(.react-datepicker__navigation--previous:focus) {
    border-right-color:black !important;
}

.calendar :global(.react-datepicker__navigation--next), .calendar :global(.react-datepicker__navigation--next:focus) {
    border-left-color:black !important;
}

.calendar :global(.react-datepicker__header) {
    background-color: white !important;
}

.calendar :global(.react-datepicker__header .react-datepicker__current-month) {
    font-size: 2rem !important;
    padding: 5px !important;
}

.calendar :global(.react-datepicker__header .react-datepicker__day-names) {
    background-color:black !important;
}

.calendar :global(.react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name) {
    color: white !important;
}

.calendar :global(.react-datepicker__day), .calendar :global(.react-datepicker__day-name) {
    width: 3rem;
    line-height: 3rem !important;
}

.calendar :global(.react-datepicker__day:hover) {
    background-color: #A6D920 !important; /* PHN Green */
}

.calendar :global(.react-datepicker__day--selected) {
    background-color: #A6D920 !important; /* PHN Green */
    color: black !important;
}