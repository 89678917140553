.network fieldset,
.network .tabs {
    vertical-align: middle;
}

.network .tabs {
    display: inline-block;
}

.tabList {
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    padding: 0px;
}

.tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.tab.selected {
    border-color: black;
    background-color: white;
}

.tabPanel {
    display: none;
}

.tabPanel.selected {
    display: block;
}