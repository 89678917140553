.moneyOrderSettings {
    display: flex;

}

.moneyOrderSettings div {
    display: inline-block;
}

.moneyOrderSettings .mainInputBlock {
    max-width: 715px;
}

.moneyOrderSettings .serialLimitBlock {
    max-width: 475px;
}

.moneyOrderSettings .serialLimitBlock fieldset {
    margin: 20px 0px;
}

.moneyOrderSettings .mainInputBlock fieldset,
.moneyOrderSettings fieldset.printerButtons {
    border: 5px solid black;
    width: 200px;
}

.moneyOrderSettings .printerButtons button,
.moneyOrderSettings label,
.moneyOrderSettings .mainInputBlock input {
    display: block;
    margin: auto;
    width: 200px;
    height: 40px;
    margin: 5px 0px;
    margin-left: auto;
    margin-right: auto;
}

.moneyOrderSettings .printerButtons button {
    margin: 15px auto;
}

.moneyOrderSettings button[type='submit'] {
    margin: 12px auto;
}

.moneyOrderSettings label {
    height: 15px;
    width: 170px;
    margin: 0px auto auto auto;
}

.moneyOrderSettings .mainInputBlock input {
    width: 170px;
}

.moneyOrderSettings .messageSaveRow fieldset {
    min-height: 80px;
}

.moneyOrderSettings .printerMessage,
.moneyOrderSettings .printerControls {
    min-width: 675px;
    min-height: 80px;
}

.moneyOrderSettings .messageSaveRow input,
.moneyOrderSettings .printerSerial input {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.moneyOrderSettings .printerControls div,
.moneyOrderSettings .limits div {
    margin: 0px 15px;
}

.moneyOrderSettings .limits,
.moneyOrderSettings .printerSerial {
    min-width: 440px;
    min-height: 80px;
}

.moneyOrderSettings .nextMO input {
    display: inline-block;
}

.moneyOrderSettings input.nextMOCheck {
    width: 35px;
}

.moneyOrderSettings input.nextMONumber {
    width: 135px;
}

.moneyOrderSettings .qrCodes {
    width: 200px;
}

.moneyOrderSettings .qrCodes fieldset {
    display: flex;
    flex-direction: column;
}

.moneyOrderSettings .qrCodes img {
    width: 96px;
    margin: auto;
}

.moneyOrderSettings .qrCodes span {
    word-break: break-all;
}

.moneyOrderSettings .qrCodes div {
    font-weight: bold;
}