@page {
    margin: 0mm;
    padding: 0mm;
    background: none;
    border: none;
    outline: 0mm;
}

@media print {
    .PrintContainer {
        display: block !important;
        text-align: center;
        font-family: sans-serif;
        font-size: 12px;
        margin: 4mm 4mm 10mm 4mm;
    }

    .PrintContainer table {
        text-align: left;
        margin: auto;
    }

    .PrintContainer.Mini {
        max-width: 58mm;
    }

    .PrintWrapper {
        display: none !important;
    }
}

@font-face {
    font-family: Avenir-Book;
    src: url('../../fonts/Avenir-Book.woff');
    font-display: block;
}

@font-face {
    font-family: Myriad-Pro-Semibold-Condensed;
    src: url('../../fonts/MyriadPro-SemiboldCond.woff');
    font-display: block;
}

body {
    margin: 0px;
}

.App {
    text-align: center;
    margin: 0;
    padding: 0;
}

.App * {
    font-family: Avenir-Book, sans-serif;
}

.App>div {
    display: flex;
}

.PrintContainer {
    display: none;
}

.App .MainSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
    font-family: Myriad-Pro-Semibold-Condensed, sans-serif;
}

.App button,
.App a.Button {
    border: none;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    background-image: none;
}

.App a {
    text-decoration: none;
}

.App a:not([disabled]):not(.disabled):focus,
.App button:not([disabled]):not([tabindex="-1"]):focus,
.App input:not([disabled]):not([tabindex="-1"]):focus,
.App textarea:not([disabled]):focus,
.App select:not([disabled]):focus {
    outline: none !important;
    border: 2px solid grey;
}

.App [tabindex]:not([disabled]):not([tabindex="-1"]):focus {
    outline: none !important;
    border: 0px;
}

.App ::selection {
    color: white;
    background-color: black;
}

.App .Focused {
    z-index: 5;
    position: relative;
}

.App .Printer {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.App .Selected {
    background-color: #A6D920;
    color: black;
    font-weight: bold;
}

.App .Hovered {
    background-color: #A6D920;
    /* PHN Green */
    color: black;
    font-weight: bold;
}

.App .Errored {
    background-color: red;
    color: white;
}

.App .Indicator {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;
    margin-right: 10px;
}

.App .Indicator.Good {
    background-color: green;
}

.App .Indicator.Bad {
    background-color: red;
}

.App .Indicator.Unknown {
    background-color: grey;
}

.App Code {
    display: block;
    margin: 0px 20px;
    padding: 20px;
    font-family: monospace;
    color: #f2f2f2;
    background-color: #262626;
    border-radius: 10px;
    border: 2px solid black;
    overflow-y: auto;
}