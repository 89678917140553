.advancedField {
    display: flex;
    flex-direction: column;
}

.advancedField .showLabel {
    margin-top: 0px;
}

.label {
    transform: translateY(100%);
    text-align: left;
    margin-left: 12px;
    z-index: -1;
}

.label.showing {
    transform: translateY(0%);
    transition: transform 200ms ease-in;
}

.label.hiding {
    transform: translateY(100%);
    transition: transform 200ms ease-out;
}

.upperCase {
    text-transform: uppercase;
}