.checkCart {
    border: 5px solid black;
    padding: 5px;
}

.checkCart .button {
    border-radius: 0px;
    background-color: #A6D920;
    color: black;
    font-size: 30px;
    padding: 0px;
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
}

.tableFieldset {
    width: 340px;
}