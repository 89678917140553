.Mainview .BillPay .CustomerForm input, .Mainview .BillPay .CustomerForm select {
    margin-bottom: 0px;
} 

.Mainview .BillPay .CustomerForm .State {
    width:114px;
}

.Mainview .BillPay .CustomerForm .Zip {
    width: 298px;
}

.Mainview .BillPay .CustomerForm .StateZip {
    display:flex;
}