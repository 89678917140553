.Mainview .CashLoad .CartForm button {
    padding-left:25px;
    padding-right:25px;
    height:36px;
    float:right;
}

.Mainview .CashLoad .CartForm {
    padding-top: 0px;
}

.Mainview .CashLoad .CartForm input {
    text-align: center;
    width: 145px;
    font-size:14px;
}

.Mainview .CashLoad .CartForm input::placeholder {
    color:white;
}

.CashLoad .CartForm .Message {
    display:inline-block;
    max-width: 150px;
}

.CashLoad .CartForm .CheckoutButtons {
    display: flex;
    justify-content: space-around;
}