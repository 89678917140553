.moneyOrderCart {
    border: 5px solid black;
    padding: 5px;
}

.moneyOrderCart .button {
    display: block;
    border-radius: 0px;
    background-color: #A6D920;
    color: black;
    font-size: 30px;
    padding: 0px;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.moneyOrderCartFieldset {
    width: 300px;
}