.sidebar {
    height: 100vh;
    width: 100%;
    min-width: 70px;
    max-width: 220px;
    background-color: #000000;
    display: flex;
    flex-direction: column;
}

.sidebar.default {
    max-width: 215px;
    min-width: 65px;
    border-right: 5px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-color: #A6D920;
    border-style: solid;
}

.buttonIconContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.sidebar a,
.sidebar button {
    background-color: black;
    border-radius: 20px;
    padding: 0px;
}

.sidebar button:hover {
    cursor: pointer;
}

.sidebar .buttonIconContainer a:focus,
.sidebar .buttonIconContainer button:focus {
    border: none !important;
}

.notificationIconBtn {
    overflow: visible;
    position: relative;
}

.notificationIconBtn img {
    width: 50px;
    height: 50px;
}

.counterCircle {
    border: 2px solid white;
    background-color: red;
    color: white;
    position: absolute;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    left: 30px;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    font-family: Myriad-Pro-Semibold-Condensed, sans-serif;
}

.bannerImageContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.bannerImageContainer.single img {
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
}

.bannerImageHeader {
    width: 100%;
}

.bannerImageHeader img {
    max-width: 90%;
    max-height: 90%;
}

.bannerImageLogoContainer {
    background-color: #A6D920;
    /* PHN Green */
    margin-bottom: 5px;
    padding-top: 5px;
}

.bannerImageHeader span {
    color: white;
}

.bannerImageList {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.bannerImageList::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.bannerImageList img {
    margin: auto;
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    object-fit: contain;
    opacity: 0;
    position: absolute;
}

.bannerImageList img.opening {
    opacity: 1;
    transition: opacity 2s ease-in;
    position: relative;
}

.bannerImageList img.closing {
    opacity: 0;
    transition: opacity 2s ease-out;
    position: relative;
}