.supportEntries {
    border-top: 2px solid black;
    margin-top: 15px;
    text-align: left;
    overflow: auto;
    max-height: 500px;
}

.supportEntry {
    padding: 10px;
    border-bottom: 2px solid black;
    background-image: url(../../../../../images/DownCaret.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 30px 30px;
}

.supportEntryTitle {
    cursor: pointer;
}

.supportEntryVideo {
    margin: auto;
}