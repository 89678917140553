.printerDownload {
    display:flex;
    flex-direction: column;
    font-size:20px;
}

.printerDownload div {
    margin:20px;
}

.printerDownload a {
    background-color:black;
    color:white !important;
    border-radius: 20px;
    margin:40px auto;
    padding:10px 50px;
    font-size:26px;
}