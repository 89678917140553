.DigitalGiftCards .Cart, .MobileTopUp .Cart {
    text-align:left;
    background-color: #A6D920;
    border-radius: 10px;
    border:solid 2px;
    font-size: 18px;
    padding: 6px;
    min-width: 240px;
    max-width: 390px;
    margin: auto;
}

.DigitalGiftCards .Cart label, .MobileTopUp .Cart label {
    vertical-align:top;
    display:inline-block;
    font-weight:bold;
    width:115px;
}

.DigitalGiftCards .Cart div, .MobileTopUp .Cart div {
    margin-bottom:10px;
}

.DigitalGiftCards .Cart span, .MobileTopUp .Cart span {
    min-width: 135px;
    max-width: 285px;
}

.DigitalGiftCards .Cart .Phone, .MobileTopUp .Cart .Phone {
    text-align:center;
    font-size:24px;
    font-weight: bold;
}

.DigitalGiftCards .Cart .Operator, .MobileTopUp .Cart .Operator {
    font-size: 22px;
}

.DigitalGiftCards .Cart .Total, .MobileTopUp .Cart .Total {
    font-weight:bold;
    font-size:30px;
    margin:0px;
}

.DigitalGiftCards .Cart .Disabled, .MobileTopUp .Cart .Disabled {
    color: grey;
}

.DigitalGiftCards .Cart .Hidden, .MobileTopUp .Cart .Hidden {
    display: none;
}