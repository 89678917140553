.virtualizedTable {
    position: relative;
}

.loadingOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.90);
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    display: flex;
    border-bottom: solid 1px lightgrey;
}

.row:not(.header):hover {
    background-color: #A6D920;
    color: black;
    font-weight: bold;
}

.header {
    background-color: black;
    color: white;
}

.cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 10px;
}