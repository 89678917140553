.networkStatus {
    text-align:left;
    max-width: 360px;
}

.networkStatus > div {
    margin: 10px;
}

.networkStatus div span {
    vertical-align: middle;
}