.basicPopup {
    position: absolute;
    background-color: #A6D920;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    z-index: 4;
}

.basicPopup.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.basicPopup.keyboard {
    top: calc(50% - 135px);
}

.fade {
    opacity: 0;
}

.fade.opening {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.fade.closing {
    opacity: 0;
    transition: opacity 200ms ease-out;
}

.basicPopup .closeButton {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    display: block;
    height: 30px;
    width: 30px;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    color: black;
    font-size: 20px;
    font-weight: bold;
}

.basicPopup.dark,
.dark .closeButton {
    color: white;
    background-color: black;
}

.basicPopup.light {
    color: black;
    background-color: white;
}

.light .closeButton {
    color: darkgray;
    background-color: white;
}