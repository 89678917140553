.customerBillers {
    margin: 20px 0px;
    width: 570px;
}

.customerBillers .button {
    color: black;
    background-color: transparent;
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
    margin: 0px;
    border-radius: 0px;
}