.Mainview,
.MainviewWrapper {
    display: flex;
    width: 100%;
}

.MainviewWrapper {
    position: relative;
    flex: 1 1 auto;
    height: 0;
    flex-direction: column;
}

.Mainview {
    overflow: auto;
    height: 100%;
}

.Mainview>* {
    margin: auto;
}

.Mainview.VirtualKeyboard {
    margin-bottom: 270px;
    height: calc(100% - 270px);
}

.Mainview h1 {
    font-size: 72px;
    margin: 20px 0px;
}

.Mainview h2 {
    font-size: 52px;
    margin: 15px 0px;
}

.Mainview h3 {
    font-size: 30px;
    margin: 10px 0px;
}

.Mainview h4 {
    font-size: 26px;
    margin: 10px 0px;
}

.Mainview h5 {
    font-size: 22px;
    margin: 5px 0px;
}

.Mainview h6 {
    font-size: 18px;
    margin: 5px 0px;
}

.Mainview select {
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    background-color: #000000;
}

.Mainview input,
.Mainview select,
.Mainview textarea {
    box-sizing: border-box;
    background-color: #A6D920;
    /* PHN Green */
    border-radius: 10px;
    font-size: 22px;
    padding: 7px;
    margin: 5px;
    width: 350px;
    text-decoration: none;
    border: 2px solid black;
}

.Mainview input[type=checkbox] {
    width: auto;
}

.Mainview input[type=search] {
    margin: auto;
    border-radius: 0px;
    background-color: white;
    appearance: textfield;
}

.Mainview input[type=search]::-webkit-search-cancel-button {
    appearance: none;
    height: 25px;
    width: 25px;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24'><path d='M24 20l-8-8 8-8-3-3-8 8-8-8-3 3 8 8-8 8 3 3 8-8 8 8z'/></svg>") no-repeat;
}

.Mainview fieldset {
    vertical-align: top;
    display: inline-block;
    border: 0 none;
}

.Mainview input.Error {
    color: red;
    border-color: red;
}

.Mainview button,
.Mainview a.Button {
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 50px;
    padding-right: 50px;
    margin: 5px;
    font-size: 12px;
}

.Mainview a {
    color: black;
}

.Mainview button:hover,
.Mainview button:focus,
.Mainview .Links a:hover,
.Mainview .Links a:focus,
.Mainview .Links button:hover,
.Mainview a.Button:hover,
.Mainview a.Button:focus {
    background-color: #A6D920;
    /* PHN Green */
    color: white;
}

.Mainview a:hover,
.Mainview a:focus {
    color: #A6D920;
    /* PHN Green */
    background-color: black;
}

.Mainview :disabled,
.Mainview input:read-only,
.Mainview a.disabled {
    -webkit-text-fill-color: white;
    /* Override iOS / Android font color change */
    opacity: 1;
    color: white;
}

.Mainview a.disabled {
    pointer-events: none;
}

.App .Mainview button:disabled,
.App .Mainview a.disabled,
.App .Mainview button:disabled:hover {
    cursor: initial;
    background-color: grey;
}

.Mainview .Links a,
.Mainview .Links button {
    display: block;
    background-color: black;
    color: white;
    border-radius: 20px;
    margin: 20px 0px;
    padding: 10px 50px;
    font-size: 18px;
    box-sizing: border-box;
    width: 300px;
}

.Mainview .Links a:hover,
.Mainview .Links a:focus,
.Mainview .Links button:hover {
    cursor: pointer;
}

.App .TrainingToggleWrapper {
    width: 100%;
    position: relative;
}