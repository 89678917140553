.notificationPopup {
    top: 5px;
    left: 5px;
    z-index: 7;
}

.title {
    margin-bottom: 10px;
    font-size: 20px;
}

.newIcon {
    width: 40px;
    height: 40px;
}

.button {
    cursor: pointer;
    font-size: 20px;
}

.preview,
.messageContainer {
    width: 495px;
    position: relative;
    text-align: left;
    background-color: white;
    color: black;
    box-sizing: border-box;
}

.previewList,
.notificationMessage {
    max-height: 292px;
    overflow-y: auto;
    overflow-x: hidden;
}

.previewList,
.notificationMessage {
    scrollbar-width: thin;
    scrollbar-color: #A6D920 #323232;
}

.previewList::-webkit-scrollbar,
.notificationMessage::-webkit-scrollbar {
    width: 30px;
}

.previewList::-webkit-scrollbar-track,
.notificationMessage::-webkit-scrollbar-track {
    background: #323232;
    border-radius: 10px;
}

.previewList::-webkit-scrollbar-thumb,
.notificationMessage::-webkit-scrollbar-thumb {
    background: #A6D920;
    border-radius: 10px;
}

.previewList {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.preview {
    margin: 5px 15px;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 10px 10px 65px;
}

.new {
    background-color: red;
    color: white;
    font-weight: bold;
}

.previewImage,
.previewDate {
    position: absolute;
    left: 10px;
}

.previewDate {
    font-size: 12px;
    top: 12px;
}

.previewImage {
    top: 1px;
}

.notificationMessage {
    padding: 0px 15px 10px 10px;
}

.messageWindow {
    position: absolute;
    top: 45px;
    left: 17px;
    width: 493px;
    height: 270px;
    border: 4px solid black;
    border-radius: 10px;
    z-index: 5;
    pointer-events: none;
}

.messageContainer {
    padding: 5px;
    margin-bottom: 20px;
}

.messageContents {
    overflow: auto;
    min-height: 230px;
}

.messageContentsFooter {
    min-height: 28px;
    overflow: auto;
    vertical-align: middle;
}

.footerDate {
    float: left;
    margin-top: 10px;
}

.footerImage {
    float: right;
}

.messageControlFooter {
    position: absolute;
    left: 15px;
    width: 505px;
    bottom: 0px;
    background-color: black;
    padding: 5px 0px;
}

.controlFooterImage {
    position: absolute;
    cursor: pointer;
}

.controlFooterImage:active {
    background-color: #A6D920;
}

.leftArrow {
    left: 5px;
}

.rightArrow {
    right: 5px;
}