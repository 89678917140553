.settings {
    display: flex;
    align-items: center;
}

.settings .info {
    width: 225px;
}

.settings .link {
    background-color: black;
    color: white;
    display: block;
    width: 300px;
    padding: 10px 0px;
    margin: 20px 0px;
    font-size: 18px;
    border-radius: 20px;
}

.settings .link:hover,
.settings .link:focus {
    background-color: #A6D920;
    color: white;
}