.wifiForm {
    min-width: 700px;
    min-height: 270px;
}

.wifiInfoBlock {
    width: 175px;
    margin-left: 20px;
}

.wifiList {
    max-height: 250px;
    overflow: auto;
    list-style: none;
    padding: 0px;
    text-align: left;
}

.wifiList li {
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.wifiList li:hover {
    background-color: #A6D920; /* PHN Green */
    color:white;
}

.wifiList input {
    background-color: white !important;
    width: 275px !important;
}

.wifiDisplayBlock {
    display:flex;
    justify-content: center;
    align-items: center;
}

.wifiRowForm {
    display:flex;
}