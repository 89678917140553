.Mainview .BillPay .BillerForm input,
.BillPay .BillerForm .PaymentInformation {
    margin-left: auto;
    margin-right: auto;
}

.Mainview .BillPay .BillerForm input[type='search'] {
    margin: 0px 75px;
}

.Mainview .BillPay .BillerForm input.Ocr {
    width: 570px;
}

.BillPay .BillerForm .PaymentInformation {
    display: inline-block;
    text-align: left;
    margin-top: 7px;
    width: 420px;
}

.BillPay .BillerForm .PaymentInformation div {
    padding: 2px;
}

.BillPay .BillerForm .PaymentTotalBlock {
    margin: 6px auto;
    width: 420px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.BillPay .BillerForm .PaymentTotal {
    font-size: 24px;
}

.Mainview .BillPay .BillerForm .Reset {
    padding-left: 25px;
    padding-right: 25px;
}

.BillPay .BillerForm .Buttons {
    width: 420px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.Mainview .BillPay .BillerForm .CashCheck input {
    margin-top: 0px;
    width: 205px;
}

.Mainview .BillPay .BillerForm .CashCheck input:read-only {
    -webkit-text-fill-color: black;
    color: black;
}

.Mainview .BillPay .BillerForm .CashCheck>div {
    display: inline-block;
    margin: 0px 5px;
}

.BillPay .BillerForm .OCRBlock {
    margin-top: 20px;
}

.BillPay .BillerForm .OCRBlock label {
    margin-right: 20px;
}

.BillPay .BillerForm .AccountConfirm {
    position: relative;
    margin: 0px 75px;
}

.BillPay .BillerForm .AccountConfirm input {
    -webkit-text-security: disc;
}

.BillPay .BillerForm .AccountConfirm input:focus,
.BillPay .BillerForm .AccountConfirm input:hover {
    -webkit-text-security: none;
}

.Mainview .BillPay .BillerForm .ExtraFieldsButton {
    margin: 40px;
}

.Mainview .BillPay .BillerForm .SuspendedMessage {
    margin: auto;
    padding: 20px;
    max-width: 420px;
    font-weight: bold;
}

.Mainview .BillPay .BillerForm .NotesTooltip {
    white-space: normal;
}