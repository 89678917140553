.wifiSignalIcon {
    width: 30px;
    height: 30px;
    margin: 10px;
    position: relative;
    overflow: hidden;
    transform: rotate(45deg);
}

.curve {
    border-radius: 50%;
    border: 5px solid black;
    position: absolute;
    bottom: 0;
    right: 0;
    transform:translate(50%,50%);
}

.curve.base {
    background-color: black;
    height: 40%;
    width: 40%;
}

.curve.low {
    width: 80%;
    height: 80%;
}

.curve.medium {
    width: 120%;
    height: 120%;
}

.curve.high {
    width: 160%;
    height: 160%;
}
